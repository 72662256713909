.image {
  width: 286px;
  height: 286px;
  border: 1px solid #bbbbb9;
  border-radius: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 32px;

  margin-bottom: 32px;
  svg {
    width: 286px;
    height: 286px;
    padding-bottom: 1px;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
}
