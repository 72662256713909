.description {
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 8px;

    span {
      color: var(--main-color);
    }
    @media (max-width: 480px) {
      font-size: 16px;
    }
  }
  :last-child {
    margin-bottom: 32px;
  }
}

.title {
  font-size: 40px;
  margin-bottom: 16px;
  @media (max-width: 480px) {
    font-size: 28px;
  }
}
