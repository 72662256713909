:global {
  body[data-theme="light"] {
    --text-color: var(--black);
  }
  body[data-theme="dark"] {
    --text-color: var(--white);
  }
}

.worksPage {
  color: var(--text-color);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 32px;
  margin-bottom: 64px;

  @media (max-width: 524px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  @media (max-width: 480px) {
    gap: 16px;
  }
  @media (max-width: 420px) {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
}
