:global {
  body[data-theme="light"] {
    --text-color: var(--black);
  }
  body[data-theme="dark"] {
    --text-color: var(--white);
  }
}

.worksItem {
  display: flex;
  justify-content: space-between;
  * {
    display: block;
  }
  svg {
    min-width: 32px;
  }
  text-decoration: none;
  padding: 24px;

  border: 1px solid #bbbbb9;
  border-radius: 16px;

  width: 100%;
  @media (max-width: 420px) {
    align-items: center;
  }
}
.worksItem:hover {
  border: 1px solid var(--main-color);
}
@media(max-width: 600px) {
  .worksItem:hover {
    border: 1px solid #bbbbb9;
  }
}

.worksItemTxt {
  .itemTxtName {
    font-size: 24px;
    color: var(--main-color);
  }
  .itemTxtTag {
    color: #bbbbb9;
    margin-top: 12px;
    font-size: 16px;
  }
  @media (max-width: 600px) {
    .itemTxtTag {
      font-size: 14px;
    }
  }
  @media (max-width: 420px) {
    .itemTxtName {
      font-size: 20px;
    }
    .itemTxtText {
      display: none;
    }
    .itemTxtTag {
      margin-top: 8px;
    }
  }
}
