:global {
  body[data-theme="light"] {
    --link-color: var(--black);
  }
  body[data-theme="dark"] {
    --link-color: var(--white);
  }
}
.theHeader {
  user-select: none;
  color: var(--link-color);

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 32px 0;

  .logo {
    font-size: 72px;
    @media (max-width: 1124px) {
      font-size: 42px;
    }
    @media (max-width: 800px) {
      margin-bottom: 16px;
    }
    @media (max-width: 480px) {
      font-size: 32px;
      margin-bottom: 32px;
    }
  }
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 480px) {
    padding: 32px 0 16px;
  }
  a {
    color: var(--link-color);
    text-decoration: none;
    padding: 12px 32px;
    border-radius: 14px;

    font-size: 18px;
    margin-right: 16px;

    border: 1px solid #bbbbb9;

    &:hover {
      border: 1px solid var(--main-color);
    }

    @media (max-width: 1124px) {
      font-size: 16px;
      padding: 12px 26px;
      border-radius: 12px;
    }
    @media (max-width: 800px) {
      display: block;
    }
    @media (max-width: 480px) {
      padding: 8px 18px;
      border-radius: 8px;
      margin-right: 0;
    }
    @media (max-width: 360px) {
      padding: 8px 12px;
    }
  }
  @media (max-width: 600px) {
    nav a:hover {
      border: 1px solid #bbbbb9;
    }
  }
  .active {
    color: var(--white);
    background: var(--main-color);
    border: 1px solid var(--main-color);
  }
  .rightHeader {
    display: flex;
    align-items: center;

    nav {
      @media (max-width: 800px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
    @media (max-width: 480px) {
      position: relative;
      width: 100%;
    }
  }
}
