.contacts {
  :last-child {
    margin-bottom: 64px;
  }
}
.title {
  font-size: 40px;
  margin-bottom: 16px;
  @media (max-width: 480px) {
    font-size: 28px;
  }
}
