* {
  box-sizing: border-box;
  margin: 0;
  transition: all .1s;
}

:root {
  --main-color: #18B7D4;
  --main-color-hover: #60ABFF;

  --white: #F5F5F5;

  --black: #282828;

  --error-color: #F74646;

  --shadow: 0px 16px 30px -10px rgba(70, 96, 187, 0.198567);
  
}


body {
  font-family: 'Space Mono', monospace;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--gray-50);
}
body::-webkit-scrollbar {
  width: 2px; 
}
body::-webkit-scrollbar-track {
  background: none;
}
body::-webkit-scrollbar-thumb {
  background-color: #18B7D4;
  border-radius: 20px;
}
body[data-theme='dark'] {
  background-color: var(--black);
}
body[data-theme='light'] {
  background-color: var(--white);
}

a {
  font-family: 'JetBrains Mono';
}