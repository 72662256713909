.dashboardWrapperSmall {
  width: 286px;
  height: 88px;
  border: 1px solid #bbbbb9;
  border-radius: 16px;
  padding: 24px;
  @media (max-width: 600px) {
    width: 100%;
    height: initial
  }
}
.sm-1 {
  grid-area: sm1;
}
.sm-2 {
  grid-area: sm2;
}
.sm-3 {
  grid-area: sm3;
}
.sm-4 {
  grid-area: sm4;
}
