.behanceStaticFyApiAdobe {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.count {
  font-size: 40px;
  @media (max-width: 600px) {
    font-size: 30px;
    margin-bottom: 4px;
  }
}
.title {
  font-size: 18px;
  @media (max-width: 600px) {
    font-size: 16px;
    margin-bottom: 16px !important;
  }
}
.link {
  font-size: 18px;
  color: var(--main-color);
  text-decoration: none;
  @media (max-width: 600px) {
    font-size: 14px;
  }
}
