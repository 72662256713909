@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500&display=swap');
#root {
  font-family: 'JetBrains Mono', monospace;
}
* {
  box-sizing: border-box;
}
.App {
  text-align: center;
  margin: 6rem auto;
  border-radius: 1rem;
}
