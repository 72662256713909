.themeSwitcher {
  cursor: pointer;
  display: flex;

  @media (max-width: 480px) {
    position: absolute;
    right: 0;
    top: -68px;
  }
}
