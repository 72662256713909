.dashboardWrapperMedium {
  width: 286px;
  height: 190px;
  border: 1px solid #bbbbb9;
  border-radius: 16px;
  padding: 24px;
  @media (max-width: 600px) {
    width: 100%;
    height: initial
  }
}
.md-1 {
  grid-area: md1;
}
.md-2 {
  grid-area: md2;
}
.md-3 {
  grid-area: md3;
}
.md-4 {
  grid-area: md4;
}
