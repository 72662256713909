:global {
  body[data-theme="light"] {
    --text-color: var(--black);
  }
  body[data-theme="dark"] {
    --text-color: var(--white);
  }
}

.dashboard {
  color: var(--text-color);
  display: grid;
  justify-content: space-between;
  grid-gap: 12px;
  grid-template-areas:
    "lg1 md1 lg2 lg3"
    "lg1 md2 lg2 lg3"
    "md3 md4 sm1 sm2"
    "md3 md4 sm3 sm4";

  margin-bottom: 64px;
  
  @media (max-width: 1220px) {
    justify-content: center;
    grid-template-areas:
      "lg1 md1"
      "lg1 md2"
      "md3 md4"
      "md3 md4"
      "lg2 lg3"
      "lg2 lg3"
      "sm1 sm2"
      "sm3 sm4";
  }
  @media (max-width: 600px) {
    grid-template-areas:
    "lg1"
    "md1"
    "md2"
    "md3"
    "md4"
    "lg2"
    "lg3"
    "sm1"
    "sm2"
    "sm3"
    "sm4";
    justify-content: initial;
    grid-gap: 16px;
  }
}
